import React, { useEffect, useState } from 'react';
import { useNotification } from '../ErrorContext';
import { ICartShopItems } from '@negotium/models';
import { useContextCartShop } from '../ContextCartShop';
import {
	cancelCartShop,
	deleteProductCartShop,
	editAmountProductCartShop,
	getProductsByCartShopId,
} from '@negotium/libCustomer';
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Card,
	CardContent,
	CircularProgress,
	Grid,
	Typography,
} from '@mui/material';
import { LoadingRectangle } from '../Skeleton';
import { ProductCartShop } from '../CartShop/ProductCartShop';
import { formatCurrency } from 'apps/customer-portal/utils/numWithComma';
import { useClientTranslation } from '@negotium/common';
import { usePathname } from 'next/navigation';

type ProductsOfCartProps = {
	lng: 'es' | 'en';
	onConfirm: () => void;
	onCloseModalCart?: () => void;
	pageCheckout?: boolean;
	disabledButton?: boolean;
	isProcessing?: boolean;
};

export const ProductsOfCart = ({
	lng,
	pageCheckout = false,
	onConfirm,
	onCloseModalCart = () => {},
	disabledButton = false,
	isProcessing = false,
}: ProductsOfCartProps) => {
	const { t } = useClientTranslation(lng, ['catalog', 'common']);

	const { setErrorNotification, setSuccessNotification } = useNotification();

	const [dataCartShop, setDataCartShop] = useState<ICartShopItems | null>(null);

	const [isLoadingCartShop, setIsLoadingCartShop] = useState<boolean>(true);
	const {
		cartShopId,
		cartShopIdWholesaler,
		setCountProductCartShop,
		discountProducts,
	} = useContextCartShop();

	const [processing, setProcessing] = useState<boolean>(false);
	const pathname = usePathname();

	const cardId = pathname.includes('wholesaler')
		? cartShopIdWholesaler
		: cartShopId;

	function getProductsOfCart() {
		getProductsByCartShopId(cardId)
			.then((response) => {
				setDataCartShop(response.data);
				setIsLoadingCartShop(false);
			})
			.catch(() => {
				setIsLoadingCartShop(false);
				setErrorNotification(t('errorGetProductsOfCartShop'));
			});
	}

	useEffect(() => {
		if (isLoadingCartShop && cardId) {
			getProductsOfCart();
		}
	}, [isLoadingCartShop, cardId]);

	function handlerDeleteProductOfCart(cartUniqueId: string) {
		deleteProductCartShop(cartUniqueId)
			.then(() => {
				setSuccessNotification(t('deleteProductOfCartSuccessfully'));
				setIsLoadingCartShop(true);
				discountProducts();
			})
			.catch(() => {
				setErrorNotification(t('deleteProductOfCartError'));
			});
	}
	function handlerEditAmountProductOfCart(cartUniqueId: string, value: number) {
		editAmountProductCartShop(cartUniqueId, value)
			.then(() => {
				setSuccessNotification(t('editAmountProductOfCartSuccessfully'));
				setIsLoadingCartShop(true);
			})
			.catch(() => {
				setErrorNotification(t('editAmountProductOfCartError'));
			});
	}
	function handlerCancelCartShop() {
		if (dataCartShop?._id) {
			setProcessing(true);
			cancelCartShop(dataCartShop._id)
				.then(() => {
					setSuccessNotification(t('cartShopCanceledSuccessfully'));
					setIsLoadingCartShop(true);
					setProcessing(false);
					setCountProductCartShop(0);
					onCloseModalCart();
				})
				.catch(() => {
					setErrorNotification(t('cartShopCanceledError'));
					setProcessing(false);
				});
		}
	}

	if (isLoadingCartShop) {
		return [...Array(4).keys()].map(() => (
			<Grid container spacing={1} marginTop={0.5}>
				<Grid item xs={9}>
					<LoadingRectangle height={'5vh'} width={'100%'} />
				</Grid>
				<Grid item xs={3}>
					<LoadingRectangle height={'5vh'} width={'100%'} />
				</Grid>
			</Grid>
		));
	}

	if (!isLoadingCartShop && !dataCartShop) {
		return (
			<Grid container>
				<Grid item xs={12}>
					<Alert severity='info'>
						<AlertTitle>{t('noProductsInCartShop')}</AlertTitle>
					</Alert>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid container rowSpacing={2} columnSpacing={1}>
			{dataCartShop?.products?.map((product) => (
				<Grid
					item
					xs={12}
					sm={pageCheckout ? 4 : 12}
					md={pageCheckout ? 3 : 12}
					key={product.cartUniqueId}
				>
					<ProductCartShop
						isCheckout={pageCheckout}
						lng={lng}
						product={product}
						onDeleteProduct={(cartUniqueId: string) =>
							handlerDeleteProductOfCart(cartUniqueId)
						}
						onEditAmount={(cartUniqueId: string, amount: number) =>
							handlerEditAmountProductOfCart(cartUniqueId, amount)
						}
						disabledButton={disabledButton}
					/>
				</Grid>
			))}
			<Grid item xs={12} display='flex'>
				<Card
					sx={{
						my: pageCheckout ? 0 : 4,
						width: '100%',
						borderRadius: 5,
						background: (theme) => theme.palette.white?.main,
						...(pageCheckout && {
							boxShadow: 'none',
						}),
					}}
				>
					<CardContent>
						<Box
							display='flex'
							justifyContent={pageCheckout ? 'center' : 'space-between'}
							alignItems='center'
						>
							<Typography
								variant='TitleSection'
								textAlign='center'
								fontSize='clamp(1rem, 3vw, 1.4rem)'
								lineHeight={'clamp(1.5rem, 4vw, 2rem)'}
								margin={0}
								fontWeight='bold'
							>
								{`${t('priceTotal')}${pageCheckout ? ': ' : ''} `}
							</Typography>
							<Typography
								margin={0}
								variant='TitleSection'
								textAlign='center'
								color='secondary.main'
								fontSize='clamp(1rem, 3vw, 1.2rem)'
								fontWeight='bold'
								sx={{
									marginLeft: pageCheckout ? 1 : 0,
								}}
							>
								{`${formatCurrency(
									dataCartShop?.priceTotal || 0,
									dataCartShop?.currency
								)} ${dataCartShop?.currency}`}
							</Typography>
						</Box>
					</CardContent>
				</Card>
			</Grid>
			{!disabledButton && (
				<Grid
					item
					xs={12}
					sx={{
						mb: 2,
					}}
				>
					<Box
						display='flex'
						flexDirection={pageCheckout ? { xs: 'column', sm: 'row' } : 'row'}
						justifyContent='center'
					>
						<Button
							color='error'
							variant='contained'
							size='medium'
							title={t('cancel', { ns: 'common' })}
							disabled={processing || isProcessing}
							onClick={() => handlerCancelCartShop()}
						>
							{processing ? <CircularProgress /> : t('back', { ns: 'common' })}
						</Button>
						<Button
							color='secondary'
							size='medium'
							variant='contained'
							title={t('confirm', { ns: 'common' })}
							disabled={processing || isProcessing}
							onClick={() => onConfirm()}
							sx={{
								marginLeft: 1,
							}}
						>
							{processing || isProcessing ? (
								<CircularProgress color='secondary' />
							) : (
								t('confirm', { ns: 'common' })
							)}
						</Button>
					</Box>
				</Grid>
			)}
		</Grid>
	);
};
